import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import fetch from 'isomorphic-fetch';
import { FirebaseInstance } from '../../firebase/Firebase';
import formatMoney from '../../../utils/formatMoney/formatMoney';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faUser, faDownload } from '@fortawesome/free-solid-svg-icons';
import './ApiPage.scss';

const sendRequest = (uri, token) => {
    return new Promise((resolve) => {

        fetch('https://rest-api.lobbyview.org/api/' + uri, {
            headers: {
                'token': token
            }
        }).then((response) => {
            resolve(response.json());
        });

    });
};

const ApiPage = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState('Please login to use LobbyView\'s REST API!');
    const [expandableShow1, setExpandableShow1] = useState(false);
    const [expandableShow2, setExpandableShow2] = useState(false);
    const [countUsers, setCountUsers] = useState(0);
    const [countRequests, setCountRequests] = useState(0);
    const [response, setResponse] = useState('');
    const [exampleRoute, setExampleRoute] = useState('legislators');
    const [exampleDescription, setExampleDescription] = useState('Information about John McCain.');
    const [exampleParameter, setExampleParameter] = useState('legislator_first_name=eq.John&legislator_last_name=eq.McCain');

    const getTotalCount = async () => {

        const response = await fetch('https://rest-api.lobbyview.org/count');
        const count = await response.json();
        if(count.users !== undefined && count.requests !== undefined) {
            setCountUsers(count.users);
            setCountRequests(count.requests);
        }

    };

    const getUserToken = async () => {

        const firebaseIdToken = await FirebaseInstance.auth?.currentUser?.getIdToken(true);
        const firebaseUserUid = FirebaseInstance.auth?.currentUser?.uid;

        if(firebaseIdToken !== undefined && firebaseUserUid !== undefined) {
            const genToken = firebaseIdToken + ':' + firebaseUserUid;

            if(genToken !== token) {
                setIsLoggedIn(true);
                setToken(genToken);
            }
        } else {
            // retry
            setTimeout(() => getUserToken(), 500);
        }

    };

    useEffect(() => {
        getTotalCount();
        getUserToken();
    }, []);

    return (
        <div>
            <Container>

                <div className="titleContainer">
                    <div className="background" />
                    <div className="titleContent">
                        <div className="titleText">
                            <div className="title">LobbyView API</div>
                            <div className="description">We provide a public REST API so that researchers can easily query from a huge dataset.</div>
                            <div className="counts">
                                <div className="countsTitle">API Usage Counter</div>
                                <div><FontAwesomeIcon icon={faUser}/> {formatMoney(countUsers)} users</div>
                                <div><FontAwesomeIcon icon={faDownload}/> {formatMoney(countRequests)} requests</div>
                            </div>
                        </div>
                        <StaticImage className="image" src='../../../images/rest-api.png' alt="REST API" />
                    </div>
                </div>

                <div className="expandableContainer">
                    <div className="title">
                            <span className="expander" onClick={() => { setExpandableShow1(!expandableShow1) }}>
                                {
                                    expandableShow1 ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>
                                }
                            </span>
                        <span>REST API</span>
                    </div>
                    {
                        expandableShow1 &&
                        <div className="description">
                            <div><b>REST API</b> is a system that queries the exact data you want from a huge database using HTTP requests.</div>

                            <div className="space" />

                            <div>The <b>endpoint</b> is the target table to search for and the <b>parameters</b> are used to query specifically.</div>

                            <div className="space" />

                            <div>Please refer to the detailed examples below.</div>
                        </div>
                    }
                </div>

                <div className="divider" />

                <div className="expandableContainer">
                    <div className="title">
                            <span className="expander" onClick={() => { setExpandableShow2(!expandableShow2) }}>
                                {
                                    expandableShow2 ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>
                                }
                            </span>
                        <span>Available API Endpoints</span>
                    </div>
                    {
                        expandableShow2 &&
                        <div className="description">

                            <div className="titleText">Legislators</div>
                            <div>Biographical information and unique identifiers for current and former Members of Congress.</div>

                            <div className="space" />

                            <div className="titleText">Bills</div>
                            <div>Record of bills and resolutions introduced in each Congress.</div>

                            <div className="space" />

                            <div className="titleText">Clients</div>
                            <div>Any person or entity that employs or retains another person for financial or other compensation to conduct lobbying activities on behalf of that person or entity. An organization employing its own in-house lobbyist(s) is considered its own client as defined in the Lobbying Disclosure Act of 1995.</div>

                            <div className="space" />

                            <div className="titleText">Reports</div>
                            <div>A quarterly report (LD-2) filed pursuant to <a href="https://uscode.house.gov/view.xhtml?req=granuleid:USC-prelim-title2-section1604&num=0&edition=prelim">2 U.S.C. § 1604</a>.</div>

                            <div className="space" />

                            <div className="titleText">Issues</div>
                            <div>The <a href="https://lda.congress.gov/LD/help/default.htm?turl=Documents%2FAppCodes.htm">General Issue Area Code(s)</a> to describe the disclosed lobbying activity under Section 15 of each report.</div>

                            <div className="space" />

                            <div className="titleText">Network</div>
                            <div>Record of the number of bills a given client reported to have lobbied on the bills that the legislator sponsored per year.</div>

                            <div className="space" />

                            <div className="titleText">Text</div>
                            <div>Textual description of Specific Lobbying Issues under Section 16 of each report.</div>

                            <div className="space" />

                            <div className="alertText">The <b>Quarter Level Network</b> and the <b>Bill Client Network</b> are currently under restricted access. Please contact <i>support@lobbyview.org</i> if you are a researcher.</div>

                            <div className="space" />

                            <div className="titleText">Quarter Level Network</div>
                            <div>Record of the number of bills a given client reported to have lobbied on the bills that the legislator sponsored per quarter.</div>

                            <div className="space" />

                            <div className="titleText">Bill Client Network</div>
                            <div>Record of lobbying activities by a client on each Congressional bill. This endpoint is available only for approved users.</div>

                        </div>
                    }
                </div>

                <div className="divider" />

                <div className="subTitle">Documentation</div>

                <div className="docContainer">

                    <div className="docText">
                        The detailed documentation about each endpoints, parameters, and examples of the LobbyView REST API is available at : <a href="https://rest-api.lobbyview.org" target="_blank">https://rest-api.lobbyview.org</a>
                        <br />
                        The list of <b>operators</b> that can be used in the <b>parameters</b> are <a href="https://postgrest.org/en/v8.0/api.html#operators">here</a>.
                    </div>

                    <div className="docText">
                        Please use your token to access the REST API. Add a HTTP header to each request using <b>token</b> as the key and token below as the value.
                        <br />
                        (It is automatically applied in the below example.)
                    </div>

                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text exampleRequestInputPrepend">Your Token</span>
                        </div>
                        <input
                            className="form-control"
                            value={token}
                            readOnly
                        />
                    </div>

                </div>

                <div className="subTitle">Limitations</div>

                <div className="docContainer">

                    <div className="docText">
                        The limit of requests of each user is <b>100 requests</b> per 24 hours.
                        <br />
                        The limit of rows of each request is <b>100 rows</b>.
                        <br />
                        A user is able to get maximum <b>10,000 rows</b> per 24 hours.
                    </div>

                    <div className="docText">
                        If the number of result rows of the query is more than 100, you won't be able to get all the results in a single query.
                        <br />
                        In this case, please use the <b>page</b> parameter.
                        <br />
                        (e.g. <b>https://rest-api.lobbyview.org/api/legislators?page=2</b> will retrieve the next 100 rows.)
                        <br />
                        The total number of pages of a query can be found inside each response.
                    </div>

                </div>

                <div className="subTitle">Examples</div>

                <div className="exampleContainer">

                    <div className="exampleRequest">

                        <div className="examplePreset">

                            <div className={`examplePresetButton ${exampleRoute === 'legislators' ? 'examplePresetButtonSelected' : ''}`} onClick={() => {
                                setExampleRoute('legislators');
                                setExampleDescription('Information about John McCain.');
                                setExampleParameter('legislator_first_name=eq.John&legislator_last_name=eq.McCain');
                            }}>
                                Legislators Example
                            </div>

                            <div className="examplePresetDivider" />

                            <div className={`examplePresetButton ${exampleRoute === 'bills' ? 'examplePresetButtonSelected' : ''}`} onClick={() => {
                                setExampleRoute('bills');
                                setExampleDescription('Bill H. 4173 from the 111th congress.');
                                setExampleParameter('congress_number=eq.111&bill_chamber=eq.H&bill_number=eq.4173');
                            }}>
                                Bills Example
                            </div>

                            <div className="examplePresetDivider" />

                            <div className={`examplePresetButton ${exampleRoute === 'clients' ? 'examplePresetButtonSelected' : ''}`} onClick={() => {
                                setExampleRoute('clients');
                                setExampleDescription('Clients which include Microsoft in their names.');
                                setExampleParameter('client_name=like.*Microsoft*');
                            }}>
                                Clients Example
                            </div>

                            <div className="examplePresetDivider" />

                            <div className={`examplePresetButton ${exampleRoute === 'reports' ? 'examplePresetButtonSelected' : ''}`} onClick={() => {
                                setExampleRoute('reports');
                                setExampleDescription('Reports in 2020 which amount is greater than $10,000,000.');
                                setExampleParameter('report_year=eq.2020&amount=gt.10000000');
                            }}>
                                Reports Example
                            </div>

                            <div className="examplePresetDivider" />

                            <div className={`examplePresetButton ${exampleRoute === 'texts' ? 'examplePresetButtonSelected' : ''}`} onClick={() => {
                                setExampleRoute('texts');
                                setExampleDescription('Reports that include covid19 in the issue text.');
                                setExampleParameter('issue_text=ilike.*covid19*');
                            }}>
                                Texts Example
                            </div>

                        </div>

                        <div className="exampleRequestInputs">

                            <div>
                                <b>{exampleDescription}</b>
                            </div>

                            <div className="input-group exampleRequestInput">
                                <div className="input-group-prepend">
                                    <span className="input-group-text exampleRequestInputPrepend">Base URL</span>
                                </div>
                                <input
                                    className="form-control"
                                    value={"https://rest-api.lobbyview.org/api/"}
                                    readOnly
                                />
                            </div>

                            <div className="input-group exampleRequestInput">
                                <div className="input-group-prepend">
                                    <span className="input-group-text exampleRequestInputPrepend">Endpoint</span>
                                </div>
                                <input
                                    className="form-control"
                                    value={exampleRoute}
                                    onChange={e => setExampleRoute(e.target.value)}
                                />
                            </div>

                            <div className="input-group exampleRequestInput">
                                <div className="input-group-prepend">
                                    <span className="input-group-text exampleRequestInputPrepend">Parameters</span>
                                </div>
                                <input
                                    className="form-control"
                                    value={exampleParameter}
                                    onChange={e => setExampleParameter(e.target.value)}
                                />
                            </div>

                            <div className="input-group exampleRequestInput">
                                <div className="input-group-prepend">
                                    <span className="input-group-text exampleRequestInputPrepend">Combined URL</span>
                                </div>
                                <input
                                    className="form-control"
                                    value={"https://rest-api.lobbyview.org/api/" + exampleRoute + "?" + exampleParameter}
                                    readOnly
                                />
                            </div>

                        </div>

                    </div>

                    <div className="exampleCodeAndResponse">

                        <div className="exampleResponse">

                            <div className="exampleHeader">
                                <div className="exampleTitle">Python Example</div>
                                <div>Try the code in your Python interpreter</div>
                            </div>

                            <pre className="exampleResponseText">{`import http.client
import json

connection = http.client.HTTPSConnection('rest-api.lobbyview.org')
headers = {
  'token': '${token}'
}
connection.request('GET', '/api/${exampleRoute}?${exampleParameter}', None, headers)

response = connection.getresponse()
data_string = response.read().decode('utf-8')
data = json.loads(data_string)

print(data)
`}</pre>

                        </div>

                        <div className="exampleResponse">

                            <div className="exampleHeader">
                                <div className="exampleTitle">Response</div>

                                <Button className="btn btn-primary exampleRequestButton" type="submit" onClick={() => {

                                    if(isLoggedIn) {

                                        setResponse('');

                                        sendRequest(exampleRoute + "?" + exampleParameter, token).then((result) => {
                                            const prettyResult = JSON.stringify(result, null, 4);
                                            setResponse(prettyResult);
                                        });

                                    } else {

                                        alert('Please login to use LobbyView\'s REST API!');

                                    }

                                }}>
                                    Get API Response
                                </Button>
                            </div>

                            <pre className="exampleResponseText">{response}</pre>

                        </div>

                    </div>

                </div>

            </Container>
        </div>
    );

};

export default ApiPage;
